// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messageadm-filter {
    padding: 15px;
    margin-bottom: 20px;
}

.messageadm-filter-name-container {
    display: flex;
    align-items: center;    
    margin-bottom: 10px;
}

.messageadm-filter-name-container input {
    border: none;
    background-color: #FFF;
    border-bottom: 1px solid #E1E1E1;
    margin-right: 10px;
}

.messageadm-filter-name-container input:focus {
    box-shadow: none;
    background-color: #FFF;
}

.messageadm-filter-name-container input::placeholder {
    color: #9E9E9E;
}

.messageadm-filter-search-icon {
    border: none;
    background-color: unset;
}

.btn-messageadm-clear {
    font-weight: bold;
    border-radius: 10px;
}

@media (min-width: 576px) {
    .messageadm-filter-form {
        display: flex;
    }

    .messageadm-filter-name-container {
        flex: 1 1;
        margin-right: 20px;
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .messageadm-filter {
        margin-left: 20px;
        flex: 1 1;
        margin-bottom: 0;
        padding: 10px 15px;
    }   
}`, "",{"version":3,"sources":["webpack://./src/components/MessageFilterAdm/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,gCAAgC;IAChC,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,SAAO;QACP,kBAAkB;QAClB,gBAAgB;IACpB;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,SAAO;QACP,gBAAgB;QAChB,kBAAkB;IACtB;AACJ","sourcesContent":[".messageadm-filter {\n    padding: 15px;\n    margin-bottom: 20px;\n}\n\n.messageadm-filter-name-container {\n    display: flex;\n    align-items: center;    \n    margin-bottom: 10px;\n}\n\n.messageadm-filter-name-container input {\n    border: none;\n    background-color: #FFF;\n    border-bottom: 1px solid #E1E1E1;\n    margin-right: 10px;\n}\n\n.messageadm-filter-name-container input:focus {\n    box-shadow: none;\n    background-color: #FFF;\n}\n\n.messageadm-filter-name-container input::placeholder {\n    color: #9E9E9E;\n}\n\n.messageadm-filter-search-icon {\n    border: none;\n    background-color: unset;\n}\n\n.btn-messageadm-clear {\n    font-weight: bold;\n    border-radius: 10px;\n}\n\n@media (min-width: 576px) {\n    .messageadm-filter-form {\n        display: flex;\n    }\n\n    .messageadm-filter-name-container {\n        flex: 1;\n        margin-right: 20px;\n        margin-bottom: 0;\n    }\n}\n\n@media (min-width: 768px) {\n    .messageadm-filter {\n        margin-left: 20px;\n        flex: 1;\n        margin-bottom: 0;\n        padding: 10px 15px;\n    }   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
