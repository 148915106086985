// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-badge-container {
    background-color: #C4C4C4;
    padding: 7px;
    color: #424242;
    font-size: 14px;
    letter-spacing: -0.015em;
    height: 33px;
    margin-right: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Users/RoleBadge/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,wBAAwB;IACxB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".role-badge-container {\n    background-color: #C4C4C4;\n    padding: 7px;\n    color: #424242;\n    font-size: 14px;\n    letter-spacing: -0.015em;\n    height: 33px;\n    margin-right: 10px;\n    border-radius: 3px;\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
