// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.classcard-card {  
    align-items: center;   
}

.classcard-container {
    padding-top: 10px; 
}

.classcard-top-container {
    font-size: 10px;
    font-family: "Roboto", "Arial", sans-serif;
    font-weight: 700;
    color: #263238;
}

.classcard-body-container {
    font-size: 10px;
    font-family: "Roboto", "Arial", sans-serif;
    font-weight: 700;
    color: #263238;
}

.classcard-videooo-container {
    text-align: center;
    
    margin-right: 20px;
    display:block;
}`, "",{"version":3,"sources":["webpack://./src/components/ClassCard/styles.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;;IAElB,kBAAkB;IAClB,aAAa;AACjB","sourcesContent":["\n.classcard-card {  \n    align-items: center;   \n}\n\n.classcard-container {\n    padding-top: 10px; \n}\n\n.classcard-top-container {\n    font-size: 10px;\n    font-family: \"Roboto\", \"Arial\", sans-serif;\n    font-weight: 700;\n    color: #263238;\n}\n\n.classcard-body-container {\n    font-size: 10px;\n    font-family: \"Roboto\", \"Arial\", sans-serif;\n    font-weight: 700;\n    color: #263238;\n}\n\n.classcard-videooo-container {\n    text-align: center;\n    \n    margin-right: 20px;\n    display:block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
