// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
    display: flex;
    justify-content:space-around;
    padding: 20px;
}

.auth-banner-container {
    display: none;
}

.auth-form-container {
    width: 100%;
}

@media (min-width: 576px) {
    .auth-form-container {
        width: 480px;
    }
}

@media (min-width: 992px) {
    .auth-form-container {
        width: 550px;
    }
}

@media (min-width: 1200px) {

    .auth-container {        
        padding: 40px;
    }

    .auth-banner-container {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        align-items: center;
    }

    .auth-banner-container h1 {
        font-size: 40px;
        letter-spacing: -0.015em;
        color: #233238;
        margin-bottom: 15px;
    }

    .auth-banner-container svg {
        width: 100%;
    }
}

@media (min-width: 4200px) {
        
    .auth-banner-container h1 {
        font-size: 55px;
        margin-bottom: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Auth/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,4BAA4B;IAC5B,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;;IAEI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;QACb,sBAAsB;QACtB,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,wBAAwB;QACxB,cAAc;QACd,mBAAmB;IACvB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;;IAEI;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":[".auth-container {\n    display: flex;\n    justify-content:space-around;\n    padding: 20px;\n}\n\n.auth-banner-container {\n    display: none;\n}\n\n.auth-form-container {\n    width: 100%;\n}\n\n@media (min-width: 576px) {\n    .auth-form-container {\n        width: 480px;\n    }\n}\n\n@media (min-width: 992px) {\n    .auth-form-container {\n        width: 550px;\n    }\n}\n\n@media (min-width: 1200px) {\n\n    .auth-container {        \n        padding: 40px;\n    }\n\n    .auth-banner-container {\n        display: flex;\n        flex-direction: column;\n        margin-top: 40px;\n        align-items: center;\n    }\n\n    .auth-banner-container h1 {\n        font-size: 40px;\n        letter-spacing: -0.015em;\n        color: #233238;\n        margin-bottom: 15px;\n    }\n\n    .auth-banner-container svg {\n        width: 100%;\n    }\n}\n\n@media (min-width: 4200px) {\n        \n    .auth-banner-container h1 {\n        font-size: 55px;\n        margin-bottom: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
