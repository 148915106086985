// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a,
a:hover {
  text-decoration: none;
  color: unset;
}

.base-card {  
  padding: 20px;
  margin-bottom: 20px;
}

.base-card2 {  
  padding: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E1E1E1;
}

.base-input {
  border-radius: 10px;
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  height: 50px;
}

.base-input::placeholder {
  color: #9e9e9e;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,oCAAoC;AACtC;;AAEA;;;;;;EAME,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ;;AAEA;;EAEE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,wBAAwB;EACxB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');\n\n* {\n  font-family: 'Open Sans', sans-serif;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-weight: 700;\n  margin-bottom: 0;\n}\n\nul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n\na,\na:hover {\n  text-decoration: none;\n  color: unset;\n}\n\n.base-card {  \n  padding: 20px;\n  margin-bottom: 20px;\n}\n\n.base-card2 {  \n  padding: 20px;\n  margin-bottom: 20px;\n  border-bottom: 1px solid #E1E1E1;\n}\n\n.base-input {\n  border-radius: 10px;\n  font-size: 1em;\n  letter-spacing: -0.015em;\n  color: #263238;\n  height: 50px;\n}\n\n.base-input::placeholder {\n  color: #9e9e9e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
