// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.classroom-container {
  font-size: 18px;
  padding-top: 10px;
}

.classroom-title-container {
  align-content: center;
  margin-left: 10px;
  margin-right: 1px;
}

.classroom-title-container h1 {
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #263238;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ClassesRoom/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,wBAAwB;EACxB,cAAc;EACd,kBAAkB;AACpB","sourcesContent":[".classroom-container {\n  font-size: 18px;\n  padding-top: 10px;\n}\n\n.classroom-title-container {\n  align-content: center;\n  margin-left: 10px;\n  margin-right: 1px;\n}\n\n.classroom-title-container h1 {\n  font-size: 1em;\n  letter-spacing: -0.015em;\n  color: #263238;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
