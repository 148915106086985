// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lecture-crud-content {
    max-width: 1200px;
    justify-content: center;
  }
  
  .btn-crud-add {
    width: 100%;
    height: 50px;
    font-size: 16px;
    letter-spacing: -0.015em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .lecture-filter-container {    
  /*PRESTA ATENÇÃO. AQUI TEMOS O EXEMPLO DE COMO CENTRALIZAR UM TEXTO E RETIRAR OS ESPAÇOS. SOFRI DEMAIS COM ISSO*/ 
    display: flex;
    align-items: center; /*Deixar o texto centralizado*/
    margin-bottom: 20; /*Retirar os espaços de baixo, centralizando ainda mais o texto*/
    flex: 1 1;  /*Pegar todo o comprimento horizontal da tela*/
    width: 100%;
  }
  
  @media (min-width: 768px) {
    .lecture-crud-bar-container {
      display: flex;
      margin-bottom: 30px;
    }
  
    .btn-crud-add {
      margin-bottom: 0;
      height: 60px;
    }
  
    .lecture-filter-container {
      margin-bottom: 0;
      margin-left: 20px;
      height: 60px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Admin/Lectures/List/styles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,wBAAwB;IACxB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;EACA,gHAAgH;IAC9G,aAAa;IACb,mBAAmB,EAAE,8BAA8B;IACnD,iBAAiB,EAAE,gEAAgE;IACnF,SAAO,GAAG,8CAA8C;IACxD,WAAW;EACb;;EAEA;IACE;MACE,aAAa;MACb,mBAAmB;IACrB;;IAEA;MACE,gBAAgB;MAChB,YAAY;IACd;;IAEA;MACE,gBAAgB;MAChB,iBAAiB;MACjB,YAAY;IACd;EACF","sourcesContent":[".lecture-crud-content {\n    max-width: 1200px;\n    justify-content: center;\n  }\n  \n  .btn-crud-add {\n    width: 100%;\n    height: 50px;\n    font-size: 16px;\n    letter-spacing: -0.015em;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  \n  .lecture-filter-container {    \n  /*PRESTA ATENÇÃO. AQUI TEMOS O EXEMPLO DE COMO CENTRALIZAR UM TEXTO E RETIRAR OS ESPAÇOS. SOFRI DEMAIS COM ISSO*/ \n    display: flex;\n    align-items: center; /*Deixar o texto centralizado*/\n    margin-bottom: 20; /*Retirar os espaços de baixo, centralizando ainda mais o texto*/\n    flex: 1;  /*Pegar todo o comprimento horizontal da tela*/\n    width: 100%;\n  }\n  \n  @media (min-width: 768px) {\n    .lecture-crud-bar-container {\n      display: flex;\n      margin-bottom: 30px;\n    }\n  \n    .btn-crud-add {\n      margin-bottom: 0;\n      height: 60px;\n    }\n  \n    .lecture-filter-container {\n      margin-bottom: 0;\n      margin-left: 20px;\n      height: 60px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
