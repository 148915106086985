// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e1e1e1;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.015em;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.pagination-item.active {
  background-color: #b8860b;
}

.pagination-link-active {
  background-color: #b8860b;
}

.arrow-previous {
  transform: rotate(-180deg);
  color: #b8860b;
}

.arrow-next {
  margin-left: 10px;
  color: #b8860b;
}

.arrow-inactive {
  color: #e1e1e1;
}

.arrow-active {
  color: #b8860b;
}

.pagination-arrow-container {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".pagination-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.pagination-item {\n  width: 40px;\n  height: 40px;\n  border-radius: 50%;\n  background-color: #e1e1e1;\n  font-size: 18px;\n  font-weight: bold;\n  letter-spacing: -0.015em;\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 10px;\n}\n\n.pagination-item.active {\n  background-color: #b8860b;\n}\n\n.pagination-link-active {\n  background-color: #b8860b;\n}\n\n.arrow-previous {\n  transform: rotate(-180deg);\n  color: #b8860b;\n}\n\n.arrow-next {\n  margin-left: 10px;\n  color: #b8860b;\n}\n\n.arrow-inactive {\n  color: #e1e1e1;\n}\n\n.arrow-active {\n  color: #b8860b;\n}\n\n.pagination-arrow-container {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
