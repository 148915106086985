// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lecture2.container {
    font-size: 18px;   
}

.lecture-title-container {    
    align-content: center;
    margin-left: 15px;
    margin-right: 1px;
}

.lecture-title-container h1 {
    font-size: 1em;
    letter-spacing: -0.015em; 
    color: #263238;       
    text-align: center;
}

@media (min-width: 768px) {

    .lecture-container { 
        font-size: 30px;
    }

}

@media (min-width: 992px) {

    .lecture-container {
        font-size: 30px;
    }

}

@media (min-width: 1200px) {

    .lecture-container {
        font-size: 30px;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/Lectures/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,wBAAwB;IACxB,cAAc;IACd,kBAAkB;AACtB;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".lecture2.container {\n    font-size: 18px;   \n}\n\n.lecture-title-container {    \n    align-content: center;\n    margin-left: 15px;\n    margin-right: 1px;\n}\n\n.lecture-title-container h1 {\n    font-size: 1em;\n    letter-spacing: -0.015em; \n    color: #263238;       \n    text-align: center;\n}\n\n@media (min-width: 768px) {\n\n    .lecture-container { \n        font-size: 30px;\n    }\n\n}\n\n@media (min-width: 992px) {\n\n    .lecture-container {\n        font-size: 30px;\n    }\n\n}\n\n@media (min-width: 1200px) {\n\n    .lecture-container {\n        font-size: 30px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
