// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.module-crud-card {
    align-items: center;
    padding: 10px 20px;
  }  
   
  .module-crud-top-container p {
    font-size: 15px;
    color: #263238;
    text-align: left;
    font-weight: 700;
  }
  
  .module-crud-container p {
    font-size: 15px;
    color: #263238;
    font-weight: 700px;
    margin: 20px;
    background-color: #c4c4c4;
    align-items: center; /*Deixar o texto centralizado*/
    text-align: center;
  }
  
  .module-crud-buttons-container {
    display: flex;
    justify-content: space-between;
  }
  
  .module-crud-card-button {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.015em;
    width: 100px;
    height: 40px;
  }
  
  .module-crud-card-button-first {
    margin-right: 10px;
  }

  .module-crud-excl {
    margin-right: 10px;
  }
  
  @media (min-width: 768px) {
    .module-crud-card {
      display: flex;
      justify-content: space-between;
    } 
  
    .module-crud-container {
      display: flex;
      justify-content: flex-start;    
    }
  
    .module-crud-container p {
      height: 40px;
      padding: 10px;      
    }
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/pages/Admin/Modules/ModuleCrudCard/styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB,EAAE,8BAA8B;IACnD,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE;MACE,aAAa;MACb,8BAA8B;IAChC;;IAEA;MACE,aAAa;MACb,2BAA2B;IAC7B;;IAEA;MACE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":[".module-crud-card {\n    align-items: center;\n    padding: 10px 20px;\n  }  \n   \n  .module-crud-top-container p {\n    font-size: 15px;\n    color: #263238;\n    text-align: left;\n    font-weight: 700;\n  }\n  \n  .module-crud-container p {\n    font-size: 15px;\n    color: #263238;\n    font-weight: 700px;\n    margin: 20px;\n    background-color: #c4c4c4;\n    align-items: center; /*Deixar o texto centralizado*/\n    text-align: center;\n  }\n  \n  .module-crud-buttons-container {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .module-crud-card-button {\n    font-weight: bold;\n    font-size: 14px;\n    letter-spacing: -0.015em;\n    width: 100px;\n    height: 40px;\n  }\n  \n  .module-crud-card-button-first {\n    margin-right: 10px;\n  }\n\n  .module-crud-excl {\n    margin-right: 10px;\n  }\n  \n  @media (min-width: 768px) {\n    .module-crud-card {\n      display: flex;\n      justify-content: space-between;\n    } \n  \n    .module-crud-container {\n      display: flex;\n      justify-content: flex-start;    \n    }\n  \n    .module-crud-container p {\n      height: 40px;\n      padding: 10px;      \n    }\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
