// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-card {  
    align-items: center;    
    /*border: 1px solid orange;
    padding-bottom: 10px;
    padding-top: 0;*/
    padding: 25px 90px 50px 90px;
    font-style: italic;
}

.message-top-container p {
    font-size: 15px;
    color: #263238;
    text-align: left;
    
}

.message-body-container p {
    font-size: 10px;
    font-family: "Roboto", "Arial", sans-serif;
    font-weight: 700;
    color: #263238;
}

.message-botton-container h1 {
    font-size: 10px;
    margin-bottom: 5px;
    font-family: "Roboto", "Arial", sans-serif;
    font-weight: 700;
    color: #263238;

}

@media (min-width: 992px) {
    .message-card {  
        align-items: center;    
        border: 1px solid #E1E1E1;
        min-height: 200px;
        max-height: 200px;
        padding: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/MessageCard/styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB;;oBAEgB;IAChB,4BAA4B;IAC5B,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;IACf,0CAA0C;IAC1C,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,0CAA0C;IAC1C,gBAAgB;IAChB,cAAc;;AAElB;;AAEA;IACI;QACI,mBAAmB;QACnB,yBAAyB;QACzB,iBAAiB;QACjB,iBAAiB;QACjB,aAAa;IACjB;AACJ","sourcesContent":[".message-card {  \n    align-items: center;    \n    /*border: 1px solid orange;\n    padding-bottom: 10px;\n    padding-top: 0;*/\n    padding: 25px 90px 50px 90px;\n    font-style: italic;\n}\n\n.message-top-container p {\n    font-size: 15px;\n    color: #263238;\n    text-align: left;\n    \n}\n\n.message-body-container p {\n    font-size: 10px;\n    font-family: \"Roboto\", \"Arial\", sans-serif;\n    font-weight: 700;\n    color: #263238;\n}\n\n.message-botton-container h1 {\n    font-size: 10px;\n    margin-bottom: 5px;\n    font-family: \"Roboto\", \"Arial\", sans-serif;\n    font-weight: 700;\n    color: #263238;\n\n}\n\n@media (min-width: 992px) {\n    .message-card {  \n        align-items: center;    \n        border: 1px solid #E1E1E1;\n        min-height: 200px;\n        max-height: 200px;\n        padding: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
