// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-nav {
    min-height: 70px;
    background-color:#FFF;    
    padding: 0 10px 0 10px;
}

.nav-logo-text, .nav-logo-text:hover {
    color: #b8860b;
    display: flex;
    align-items: center;
}

.nav-logo-text h4 {
    margin-left: 10px;
}

.main-menu {
    display: flex;
    font-size: 18px;
    width: 100%;
    justify-content:space-between;    
}

.main-menu a {
    color:rgba(56, 53, 53, 0.911);
}

.main-menu a:hover {
    color: #b8860b;
}

.main-menu a.active {
    color: #b8860b;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".main-nav {\n    min-height: 70px;\n    background-color:#FFF;    \n    padding: 0 10px 0 10px;\n}\n\n.nav-logo-text, .nav-logo-text:hover {\n    color: #b8860b;\n    display: flex;\n    align-items: center;\n}\n\n.nav-logo-text h4 {\n    margin-left: 10px;\n}\n\n.main-menu {\n    display: flex;\n    font-size: 18px;\n    width: 100%;\n    justify-content:space-between;    \n}\n\n.main-menu a {\n    color:rgba(56, 53, 53, 0.911);\n}\n\n.main-menu a:hover {\n    color: #b8860b;\n}\n\n.main-menu a.active {\n    color: #b8860b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
