// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.medium-crud-form-card {
    padding: 20px;
}

.medium-crud-form-title {
    font-weight: bold;
    font-size: 22px;
    letter-spacing: -0.015em;
    color: #263238;
    margin-bottom: 30px;
}

.margin-botton-30 {
    margin-bottom: 30px;
}

.medium-crud-inputs-container {
    margin-bottom: 30px;
}

.medium-crud-inputs-left-container {
    margin-bottom: 30px;
}

.xpto-agora-vai {
    display: flex;
    justify-content: space-between;
}

.xpto-button {
    height: 50px;
    width: 110px;
    font-weight: bold;
}

.medium-crud-select__control {
    border-radius: 10px !important;
    font-size: 1em;
    letter-spacing: -0.015em;
    background-color: #F2F2F2 !important;
    height: 50px;
}

.medium-crud-select__placeholder {
    color: #9E9E9E!important;
}

@media (min-width: 576px) {
    .xpto-agora-vai {
        justify-content:flex-end;
    }

    .xpto-button {
        margin-left: 30px;
        width: 250px;
    }
}

@media (min-width: 992px) {
    .medium-crud-inputs-left-container {
        margin-bottom: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Mediuns/Form/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,wBAAwB;IACxB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,8BAA8B;IAC9B,cAAc;IACd,wBAAwB;IACxB,oCAAoC;IACpC,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI;QACI,wBAAwB;IAC5B;;IAEA;QACI,iBAAiB;QACjB,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".medium-crud-form-card {\n    padding: 20px;\n}\n\n.medium-crud-form-title {\n    font-weight: bold;\n    font-size: 22px;\n    letter-spacing: -0.015em;\n    color: #263238;\n    margin-bottom: 30px;\n}\n\n.margin-botton-30 {\n    margin-bottom: 30px;\n}\n\n.medium-crud-inputs-container {\n    margin-bottom: 30px;\n}\n\n.medium-crud-inputs-left-container {\n    margin-bottom: 30px;\n}\n\n.xpto-agora-vai {\n    display: flex;\n    justify-content: space-between;\n}\n\n.xpto-button {\n    height: 50px;\n    width: 110px;\n    font-weight: bold;\n}\n\n.medium-crud-select__control {\n    border-radius: 10px !important;\n    font-size: 1em;\n    letter-spacing: -0.015em;\n    background-color: #F2F2F2 !important;\n    height: 50px;\n}\n\n.medium-crud-select__placeholder {\n    color: #9E9E9E!important;\n}\n\n@media (min-width: 576px) {\n    .xpto-agora-vai {\n        justify-content:flex-end;\n    }\n\n    .xpto-button {\n        margin-left: 30px;\n        width: 250px;\n    }\n}\n\n@media (min-width: 992px) {\n    .medium-crud-inputs-left-container {\n        margin-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
