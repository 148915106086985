// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-nav-items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.admin-nav-container {
    padding: 10px;
    background-color: #FFF;    
}

.admin-nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #FFF;
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 0.015em;
    color: #9E9E9E;    
}

.admin-nav-item.active {
    background: rgba(64, 123, 255, 0.3);
    color: #b8860b;
}

.admin-nav-item p {
    margin-bottom: 0;
}

@media (min-width: 992px) {

    .admin-nav-items-container {
        justify-content: unset;
        flex-direction: column;
    }

    .admin-nav-container {        
        padding: 0;
        width: 300px;
        height: calc(100vh - 70px);
        
    }    

    .admin-nav-item {
        border-radius: 0;
        font-size: 18px;
        height: 80px;
        background-color: #FFF;
        border-bottom: 1px solid #E1E1E1;
        justify-content: flex-start;
        padding-left: 40px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Navbar/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,6BAA6B;IAC7B,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,cAAc;AAClB;;AAEA;IACI,mCAAmC;IACnC,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI;QACI,sBAAsB;QACtB,sBAAsB;IAC1B;;IAEA;QACI,UAAU;QACV,YAAY;QACZ,0BAA0B;;IAE9B;;IAEA;QACI,gBAAgB;QAChB,eAAe;QACf,YAAY;QACZ,sBAAsB;QACtB,gCAAgC;QAChC,2BAA2B;QAC3B,kBAAkB;IACtB;;AAEJ","sourcesContent":[".admin-nav-items-container {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.admin-nav-container {\n    padding: 10px;\n    background-color: #FFF;    \n}\n\n.admin-nav-item {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 50px;\n    background-color: #FFF;\n    padding: 0 10px;\n    font-size: 16px;\n    letter-spacing: 0.015em;\n    color: #9E9E9E;    \n}\n\n.admin-nav-item.active {\n    background: rgba(64, 123, 255, 0.3);\n    color: #b8860b;\n}\n\n.admin-nav-item p {\n    margin-bottom: 0;\n}\n\n@media (min-width: 992px) {\n\n    .admin-nav-items-container {\n        justify-content: unset;\n        flex-direction: column;\n    }\n\n    .admin-nav-container {        \n        padding: 0;\n        width: 300px;\n        height: calc(100vh - 70px);\n        \n    }    \n\n    .admin-nav-item {\n        border-radius: 0;\n        font-size: 18px;\n        height: 80px;\n        background-color: #FFF;\n        border-bottom: 1px solid #E1E1E1;\n        justify-content: flex-start;\n        padding-left: 40px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
