// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.psychography-card {  
    align-items: center;    
    /*border: 1px solid orange;*/
}

.psychography-top-container p {
    font-size: 15px;
    color: #263238;
    text-align: left;
    
}

.psychography-body-container p {
    font-size: 15px;
    color: #263238;
    text-align: left;
}

.psychography-botton-container h1 {
    font-size: 18px;
    color: #263238;
    text-align: left;
}

@media (min-width: 768px) {
    .psychography-card {  
        align-items: center;    
        margin-left: 100px;
        margin-right: 100px;
    }
  }

  @media (min-width: 992px) {
    .psychography-card {  
        align-items: center;    
        margin-left: 300px;
        margin-right: 300px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/PsychographyCard/styles.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;;AAEpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;QACnB,kBAAkB;QAClB,mBAAmB;IACvB;EACF;;EAEA;IACE;QACI,mBAAmB;QACnB,kBAAkB;QAClB,mBAAmB;IACvB;EACF","sourcesContent":["\n.psychography-card {  \n    align-items: center;    \n    /*border: 1px solid orange;*/\n}\n\n.psychography-top-container p {\n    font-size: 15px;\n    color: #263238;\n    text-align: left;\n    \n}\n\n.psychography-body-container p {\n    font-size: 15px;\n    color: #263238;\n    text-align: left;\n}\n\n.psychography-botton-container h1 {\n    font-size: 18px;\n    color: #263238;\n    text-align: left;\n}\n\n@media (min-width: 768px) {\n    .psychography-card {  \n        align-items: center;    \n        margin-left: 100px;\n        margin-right: 100px;\n    }\n  }\n\n  @media (min-width: 992px) {\n    .psychography-card {  \n        align-items: center;    \n        margin-left: 300px;\n        margin-right: 300px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
