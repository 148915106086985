// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.endereco-container {
    font-size: 0.7em;
}

@media (min-width: 576px) {

    .endereco-container {
        font-size: 1.0em;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;;IAEI;QACI,gBAAgB;IACpB;;AAEJ","sourcesContent":[".endereco-container {\n    font-size: 0.7em;\n}\n\n@media (min-width: 576px) {\n\n    .endereco-container {\n        font-size: 1.0em;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
