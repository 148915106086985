// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.class-crud-content {
  max-width: 1200px;
  justify-content: center;
}

.class-crud-bar-container {
  margin-bottom: 20px;
}

.btn-crud-add {
  width: 100%;
  height: 50px;
  font-size: 16px;
  letter-spacing: -0.015em;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .class-crud-bar-container {
    display: flex;
  }

  .btn-crud-add {
    margin-bottom: 0;
    height: 60px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Classes/List/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,wBAAwB;EACxB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;AACF","sourcesContent":[".class-crud-content {\n  max-width: 1200px;\n  justify-content: center;\n}\n\n.class-crud-bar-container {\n  margin-bottom: 20px;\n}\n\n.btn-crud-add {\n  width: 100%;\n  height: 50px;\n  font-size: 16px;\n  letter-spacing: -0.015em;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\n\n@media (min-width: 768px) {\n  .class-crud-bar-container {\n    display: flex;\n  }\n\n  .btn-crud-add {\n    margin-bottom: 0;\n    height: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
