// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lecture2-filter {
    padding: 5px;
    margin-bottom: 20px;
}

.lecture2-filter-name-container {
    display: flex;
    align-items: center;    
    margin-bottom: 10px;
}

.lecture2-filter-name-container input {
    border: none;
    background-color: #FFF;
    border-bottom: 1px solid #E1E1E1;
}

.lecture2-filter-name-container input:focus {
    box-shadow: none;
    background-color: #FFF;
}

.lecture2-filter-name-container input::placeholder {
    color: #9E9E9E;
}

.lecture2-filter-search-icon {
    border: none;
    background-color: unset;
}

.btn-lecture2-clear {
    font-weight: bold;
    border-radius: 1px;
    padding: 1px;
    color: #9E9E9E;
}

@media (min-width: 576px) {
    .lecture2-filter-form {
        display: flex;
    }

    .lecture2-filter-name-container {
        flex: 1 1;
        margin-right: 20px;
        margin-bottom: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/LectureFilter/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,SAAO;QACP,kBAAkB;QAClB,gBAAgB;IACpB;AACJ","sourcesContent":[".lecture2-filter {\n    padding: 5px;\n    margin-bottom: 20px;\n}\n\n.lecture2-filter-name-container {\n    display: flex;\n    align-items: center;    \n    margin-bottom: 10px;\n}\n\n.lecture2-filter-name-container input {\n    border: none;\n    background-color: #FFF;\n    border-bottom: 1px solid #E1E1E1;\n}\n\n.lecture2-filter-name-container input:focus {\n    box-shadow: none;\n    background-color: #FFF;\n}\n\n.lecture2-filter-name-container input::placeholder {\n    color: #9E9E9E;\n}\n\n.lecture2-filter-search-icon {\n    border: none;\n    background-color: unset;\n}\n\n.btn-lecture2-clear {\n    font-weight: bold;\n    border-radius: 1px;\n    padding: 1px;\n    color: #9E9E9E;\n}\n\n@media (min-width: 576px) {\n    .lecture2-filter-form {\n        display: flex;\n    }\n\n    .lecture2-filter-name-container {\n        flex: 1;\n        margin-right: 20px;\n        margin-bottom: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
