// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-container {
    display: flex;
    flex-direction: column;
}

.admin-content {
    padding: 20px;
}

@media (min-width: 992px) {
    .admin-container {
        flex-direction: row;
    } 

    .admin-content {
        flex: 1 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,SAAO;IACX;AACJ","sourcesContent":[".admin-container {\n    display: flex;\n    flex-direction: column;\n}\n\n.admin-content {\n    padding: 20px;\n}\n\n@media (min-width: 992px) {\n    .admin-container {\n        flex-direction: row;\n    } \n\n    .admin-content {\n        flex: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
