// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psychography-container {
    font-size: 18px;
    /*border: 1px solid rgb(25, 0, 255);*/
    padding: 10px 10px 75px 10px;
}

.psychography-title-container {
    margin-bottom: 20px; 
    align-content: center;   
    margin-left: 0px;
    margin-right: 0px;
}

.psychography-title-container h1 {
    font-size: 1em;
    letter-spacing: -0.015em;
    color: #263238;       
    text-align: center;
}

@media (min-width: 768px) {

    .psychography-container {
        font-size: 30px;
    }

}

@media (min-width: 992px) {

    .psychography-container {
        font-size: 30px;
    }

}

@media (min-width: 1200px) {

    .psychography-container {
        font-size: 30px;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/Psychographies/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qCAAqC;IACrC,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,wBAAwB;IACxB,cAAc;IACd,kBAAkB;AACtB;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ;;AAEA;;IAEI;QACI,eAAe;IACnB;;AAEJ","sourcesContent":[".psychography-container {\n    font-size: 18px;\n    /*border: 1px solid rgb(25, 0, 255);*/\n    padding: 10px 10px 75px 10px;\n}\n\n.psychography-title-container {\n    margin-bottom: 20px; \n    align-content: center;   \n    margin-left: 0px;\n    margin-right: 0px;\n}\n\n.psychography-title-container h1 {\n    font-size: 1em;\n    letter-spacing: -0.015em;\n    color: #263238;       \n    text-align: center;\n}\n\n@media (min-width: 768px) {\n\n    .psychography-container {\n        font-size: 30px;\n    }\n\n}\n\n@media (min-width: 992px) {\n\n    .psychography-container {\n        font-size: 30px;\n    }\n\n}\n\n@media (min-width: 1200px) {\n\n    .psychography-container {\n        font-size: 30px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
