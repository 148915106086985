// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-card {
  padding: 20px;
  font-size: 16px;
}

.login-card h1 {
  font-size: 2.5em;
  font-weight: normal;
  text-align: center;
  letter-spacing: -0.015em;
  color: #263238;
  text-transform: uppercase;
  margin-top: 70px;
  margin-bottom: 50px;
}

.login-link-recover {
  font-size: 1em;
  letter-spacing: -0.015em;
  color: #b8860b;
  display: block;
  margin-bottom: 90px;
}

.login-link-register {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  text-decoration-line: underline;
  color: #b8860b;
  margin-left: 10px;
}

.login-submit {
  padding: 80px 0px;
  display: 1;
}

.not-registered {
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  letter-spacing: -0.015em;
  color: #9e9e9e;
}

.signup-container {
  text-align: center;
  margin-bottom: 30px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Auth/Login/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;EACxB,cAAc;EACd,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,wBAAwB;EACxB,cAAc;EACd,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,wBAAwB;EACxB,+BAA+B;EAC/B,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,wBAAwB;EACxB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".login-card {\n  padding: 20px;\n  font-size: 16px;\n}\n\n.login-card h1 {\n  font-size: 2.5em;\n  font-weight: normal;\n  text-align: center;\n  letter-spacing: -0.015em;\n  color: #263238;\n  text-transform: uppercase;\n  margin-top: 70px;\n  margin-bottom: 50px;\n}\n\n.login-link-recover {\n  font-size: 1em;\n  letter-spacing: -0.015em;\n  color: #b8860b;\n  display: block;\n  margin-bottom: 90px;\n}\n\n.login-link-register {\n  font-weight: bold;\n  font-size: 1em;\n  text-align: center;\n  letter-spacing: -0.015em;\n  text-decoration-line: underline;\n  color: #b8860b;\n  margin-left: 10px;\n}\n\n.login-submit {\n  padding: 80px 0px;\n  display: 1;\n}\n\n.not-registered {\n  font-weight: bold;\n  font-size: 1em;\n  text-align: center;\n  letter-spacing: -0.015em;\n  color: #9e9e9e;\n}\n\n.signup-container {\n  text-align: center;\n  margin-bottom: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
