
const tokenKey = 'authData';

type loginResponse = {
    access_token: string;
    token_type: string;
    expires_in: number;
    scope: string;
    userFirstName: string;
    userId: number;
  };

export const saveAuthData = (obj: loginResponse) => {
    localStorage.setItem(tokenKey, JSON.stringify(obj));
  };
  
  export const getAuthData = () => {
    const str = localStorage.getItem(tokenKey) ?? '{}';
    return JSON.parse(str) as loginResponse;
  };
  
  export const removeAuthData = () => {
    localStorage.removeItem(tokenKey);
  };