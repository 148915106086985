// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container button {
  width: 100%;
}

.btn h6 {
  color: #fff;
  font-size: 18px;
  padding: 10px;
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonIcon/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".btn-container button {\n  width: 100%;\n}\n\n.btn h6 {\n  color: #fff;\n  font-size: 18px;\n  padding: 10px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
